import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setFilter, resetFilters } from '../../../reducers/category.reducer';

const colors = [
  { name: 'Vegaaninen', color: '#C1E5C0' },
  { name: 'Keitto', color: '#FFCD93' },
  { name: 'Max 30 min', color: '#C0DAE5' },
  { name: 'Pääruoka', color: '#FFC3D8' },
  { name: 'Jälkiruoka', color: '#FFC3D8' },
  { name: 'Salaatti', color: '#FFCD93' },
  { name: 'Lisuke', color: '#C1E5C0' },
  { name: 'Pasta', color: '#F1E4FF' },
  { name: 'Lähi-itä/Pohjois-Afrikka', color: '#FFCD93' },
  { name: 'Aasia', color: '#C0DAE5' },
  { name: 'Italia', color: '#F1E4FF' },
  { name: 'Meksiko', color: '#FFC3D8' },
];

const CategoryCard = ({ category }) => {
  const bgColor = colors.find((element) => element.name === category.name);
  const styles = {
    backgroundColor: `${bgColor.color}`,
  };
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(resetFilters());
    dispatch(setFilter(category.pk));
  };

  return (
    <div className="category-card">
      <Link
        to="/reseptit"
        onClick={handleClick}
      >
        <div style={styles} className="category-card__image-container">
          <img className="category-card__image" src={category.image} alt="" />
        </div>
        <div className="category-card__content">
          <p className="category-card__name">{category.name}</p>
        </div>
      </Link>
    </div>
  );
};

CategoryCard.propTypes = {
  category: PropTypes.instanceOf(Object).isRequired,
};

export default CategoryCard;
