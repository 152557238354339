import React from 'react';
import PropTypes from 'prop-types';

const Ingredient = ({ name, amount, unit }) => (
  <div className="ingredient-text">
    <span className="ingredient-name">{name}</span>
    <div className="ingredient-amount-unit">
      <span className="ingredient-amount">{amount}</span>
      <span className="ingredient-amount">{unit}</span>
    </div>
  </div>
);

Ingredient.propTypes = {
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
};

export default Ingredient;
