import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CustomInput from '../../common/inputs/CustomInput';
import RAVCompulsoryIngredients from './compulsory-ingredients/RAVCompulsoryIngredients';
import RAVOptionalIngredients from './optional-ingredients/RAVOptionalIngredients';

const RAVIngredients = ({
  values,
  ingredients,
  setValues,
  submitted,
}) => {
  let options = ingredients.map((item) => {
    const newItem = {
      sk: item.pk,
      name: item.name,
      value: `${item.name} (${item.category})`,
      label: `${item.name} (${item.category})`,
    };
    return newItem;
  });
  options = _.sortBy(options, 'label');

  const handleInputChange = (name) => (e) => {
    const { value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <>
      <h2 id="ingredients-heading">Ainekset</h2>
      <CustomInput
        name="meals"
        title="Monenko annoksen perusteella luot reseptin?"
        value={values.meals}
        handleChange={handleInputChange('meals')}
        min="0"
        type="number"
        required="true"
        submitted
      >
        <p className="form-hint">Ainekset lisätään tälle henkilömäärälle.</p>
      </CustomInput>
      <RAVCompulsoryIngredients
        options={options}
        values={values}
        setValues={setValues}
        submitted={submitted}
      />
      <RAVOptionalIngredients
        options={options}
        values={values}
        setValues={setValues}
        submitted={submitted}
      />
    </>
  );
};

RAVIngredients.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  ingredients: PropTypes.instanceOf(Array).isRequired,
  setValues: PropTypes.instanceOf(Function).isRequired,
  submitted: PropTypes.bool.isRequired,
};

export default RAVIngredients;
