import React from 'react';
import PropTypes from 'prop-types';

const SVCustomIngredient = ({ ingredient }) => (
  <div className="ingredient-text">
    <span className="ingredient-custom">{ingredient.text}</span>
  </div>
);

SVCustomIngredient.propTypes = {
  ingredient: PropTypes.instanceOf(Object).isRequired,
};

export default SVCustomIngredient;
