import React, { useEffect, useState } from 'react';
import Notification from '../common/notification/Notification';
import recipeService from '../../services/recipe.service';
import RAVSideNav from './side-nav/RAVSideNav';
import RAVBasicInfo from './basic-info/RAVBasicInfo';
import RAVIngredients from './ingredients/RAVIngredients';
import RAVImageUpload from './image-upload/RAVImageUpload';
import RAVInstructions from './instructions/RAVInstructions';
import ButtonPrimary from '../common/buttons/button-primary/ButtonPrimary';
import RAVCategories from './categories/RAVCategories';

const initialValues = {
  categories: [],
  chef: '',
  description: '',
  duration: 30,
  image: NaN,
  ingredientLinks: [{
    sk: '',
    name: '',
    amount: '',
    unit: '',
    steps: [],
  }],
  instructions: [{
    description: '',
    step: 1,
  }],
  meals: 2,
  name: '',
  optionalIngredients: [],
  pk: '',
  sk: 'RECIPE',
  website: '',
};

const RecipeAddition = () => {
  const [values, setValues] = useState(initialValues);
  const [submitted, setSubmitted] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [sending, setSending] = useState(false);

  const handleSubmit = async (e) => {
    setSubmitted(true);
    setSending(true);
    e.preventDefault();
    setNotificationMessage('Lähetetään...');
    try {
      const pk = await recipeService.createPK();
      const imageUrls = await recipeService.addImage(pk, values.image);
      const newValues = {
        ...values,
        ...imageUrls,
        pk,
      };
      const result = await recipeService.addRecipe(newValues);
      console.log(result);
      setNotificationMessage('Resepti lisätty onnistuneesti');
      setValues(initialValues);
    } catch (error) {
      setNotificationMessage('Reseptin lähetyksessä tapahtui virhe');
    } finally {
      setSending(false);
      setTimeout(() => {
        setNotificationMessage(null);
      }, 5000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const newIngredients = await recipeService.getIngredients();
      setIngredients(newIngredients);
    };
    fetchData();
  }, []);

  return (
    <div name="recipe-addition" className="recipe-addition-bg" id="recipe-addition">
      <div className="recipe-addition-content flex">
        <RAVSideNav />
        <div className="recipe-addition-form-wrapper">
          <h1 className="recipe-addition-title">Lisää resepti Slurpsiin</h1>
          <form onSubmit={handleSubmit}>
            <RAVBasicInfo
              values={values}
              setValues={setValues}
              submitted={submitted}
            />
            <RAVImageUpload
              values={values}
              setValues={setValues}
              submitted={submitted}
            />
            <RAVCategories
              values={values}
              setValues={setValues}
            />
            <RAVIngredients
              values={values}
              ingredients={ingredients}
              setValues={setValues}
              submitted={submitted}
            />
            <RAVInstructions
              values={values}
              setValues={setValues}
              submitted={submitted}
            />
            <div id="submit-recipe">
              <ButtonPrimary disabled={sending} type="submit" handleClick={setSubmitted} text="Lähetä resepti" />
            </div>
          </form>
          <Notification message={notificationMessage} />
        </div>
      </div>
    </div>
  );
};

export default RecipeAddition;
