import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ErrorMessage from '../../../common/error-message/ErrorMessage';
import Ingredient from './RAVIngredient';
import ButtonSecondary from '../../../common/buttons/button-secondary/ButtonSecondary';
import ButtonTertiary from '../../../common/buttons/button-tertiary/ButtonTertiary';

const RAVCompulsoryIngredients = ({
  submitted,
  values,
  setValues,
  options,
}) => {
  const addIngredient = () => {
    const newIngredientLinks = values.ingredientLinks.concat({
      sk: '',
      name: '',
      amount: '',
      unit: '',
      steps: [],
    });
    setValues({
      ...values,
      ingredientLinks: newIngredientLinks,
    });
  };

  const removeIngredient = () => {
    const { length } = values.ingredientLinks;
    if (length > 1) {
      const { ingredientLinks } = values;
      const newIngredients = ingredientLinks.filter(
        (value, index) => index !== (length - 1),
      );
      setValues({
        ...values,
        ingredientLinks: newIngredients,
      });
    }
  };

  return (
    <>
      <h3>Pakolliset ainekset</h3>
      {
      submitted && values.ingredientLinks.find((ingredient) => ingredient.sk === '' || ingredient.amount === '' || ingredient.unit === '')
        ? <ErrorMessage message="Täytä puuttuvat tiedot tai poista ylimääräinen aines." />
        : <p className="form-hint">Kirjoita aines monikossa: esim. Tomaatteja. Käytä jotakin näistä yksiköistä: kpl, g, tl, rkl, dl.</p>
      }
      {
        _.range(values.ingredientLinks.length).map((num) => (
          <Ingredient
            key={`ingredient${num}`}
            num={num}
            options={options}
            values={values}
            setValues={setValues}
            submitted={submitted}
          />
        ))
      }
      <div className="flex flex-ai-c">
        <ButtonSecondary handleClick={addIngredient} text="+ Lisää aines" />
        {
          values.ingredientLinks.length <= 1
            ? null
            : <ButtonTertiary handleClick={removeIngredient} text="Poista aines" />
        }
      </div>
    </>
  );
};

RAVCompulsoryIngredients.propTypes = {
  submitted: PropTypes.bool.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  setValues: PropTypes.instanceOf(Function).isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
};

export default RAVCompulsoryIngredients;
