import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { addMeals, reduceMeals } from '../../../../../reducers/recipe.reducer';
import ButtonTertiary from '../../../../common/buttons/button-tertiary/ButtonTertiary';
import IconMinus from '../../../../../images/icon__minus.svg';
import IconPlus from '../../../../../images/icon__plus.svg';

const RVMealCounter = ({ pk, meals }) => {
  const dispatch = useDispatch();
  const AddMeal = () => {
    dispatch(addMeals(pk));
  };

  const SubtractMeal = () => {
    if (meals > 1) {
      dispatch(reduceMeals(pk));
    }
  };

  return (
    <div className="meal-counter-wrapper">
      <div className="meal-counter_container flex-vertical flex-ai-c">
        <p className="meal-counter_title">Annoksia</p>
        <div className="meal-counter flex flex-jc-sb flex-ai-c">
          <ButtonTertiary handleClick={SubtractMeal} image={IconMinus} counter />
          <div className="current-count">{meals}</div>
          <ButtonTertiary handleClick={AddMeal} image={IconPlus} counter />
        </div>
      </div>
    </div>
  );
};

RVMealCounter.propTypes = {
  pk: PropTypes.string.isRequired,
  meals: PropTypes.number.isRequired,
};

export default RVMealCounter;
