const getShoppingListRecipes = ({ recipes }) => (
  recipes.filter((recipe) => recipe.added)
);

const getShoppingListIngredients = ({ recipes }) => (
  recipes.reduce((acc, cur) => {
    if (cur.added) {
      return acc.concat(cur.ingredients);
    }
    return acc;
  }, [])
);

const getShoppingListOptionalIngredients = ({ recipes }) => (
  recipes.reduce((acc, cur) => {
    if (cur.added && cur.optionalIngredients) {
      return acc.concat(cur.optionalIngredients);
    }
    return acc;
  }, [])
);

export default {
  getShoppingListRecipes,
  getShoppingListIngredients,
  getShoppingListOptionalIngredients,
};
