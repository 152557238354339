import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '../../../icons/ArrowRightIcon';

const FeatureCard = ({
  image,
  heading,
  text,
  linkURL,
  linkText,
  isMiddle,
  randomRecipe,
}) => {
  const recipes = useSelector((state) => state.recipes);
  const id = Math.floor(Math.random() * recipes.length);
  const randomRecipeUrl = recipes[id].name;

  return (
    <div className={`feature-card-wrapper flex flex-ai-c ${isMiddle ? 'feature-card--is-middle' : ''}`}>
      <div className="feature-card-container feature-card-text-container flex flex-vertical">
        <h3>{ heading }</h3>
        <p>{ text }</p>
        <div className="feature-card-cta-container flex flex-ai-c">
          <Link
            to={`${randomRecipe ? `/reseptit/${randomRecipeUrl}` : linkURL}`}
            className="feature-card-cta-container__link page-link text--sb currentcolor"
          >
            { linkText }
          </Link>
          <ArrowRightIcon />
        </div>
      </div>
      <div className="feature-card-container">
        <img src={image} alt="" />
      </div>
    </div>
  );
};

FeatureCard.defaultProps = {
  isMiddle: false,
  randomRecipe: false,
};

FeatureCard.propTypes = {
  image: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  linkURL: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  isMiddle: PropTypes.bool,
  randomRecipe: PropTypes.bool,
};

export default FeatureCard;
