import React from 'react';
import FeatureCard from '../../common/feature-card/FeatureCard';
import FeatureShoppingList from '../../../images/feature-shoppinglist.png';
import FeatureCooking from '../../../images/feature-cooking.png';
import FeatureRecipes from '../../../images/feature-recipes.png';

const HVFeatures = () => (
  <section className="features-section flex flex-vertical flex-ai-c">
    <div className="features-header flex flex-vertical">
      <h2 className="features-header__text">Ilahduttavan reseptisivuston ainekset</h2>
      <p className="features-header__text text--xl">Slurpsin tavoite on tuoda kasvisruokaa pilke silmäkulmassa useampaan pöytään. Tässä suunnitelmamme.</p>
    </div>
    <FeatureCard
      image={FeatureShoppingList}
      heading="Ostoslista, joka hoitaa kaiken"
      text="Täp-täp-tadaa! Lisää vaikka viikon reseptit ja anna fiksun ostoslistan hoitaa loput. Löydät kaikki ainekset oikeilla määrillä oikeilta kaupan hyllyiltä."
      linkURL="/reseptit"
      linkText="Selaa reseptejä"
    />
    <FeatureCard
      image={FeatureCooking}
      heading="Verkon paras kokkauskokemus"
      text="Väsyttääkö skrollata reseptiä ylös ja alas paikallistaaksesi oikean vaiheen ja ainekset? Slurpsissa näet kaiken mitä tarvitset etkä mitään ylimääräistä."
      linkURL="reseptit/Kukkakaali-ramen%20tofulla"
      linkText="Kokeile satunnaista reseptiä 🎲"
      isMiddle
      randomRecipe
    />
    <FeatureCard
      image={FeatureRecipes}
      heading="Ja tietysti parhaat reseptit"
      text="Mitäpä jos jokainen kohtaamasi resepti olisi paitsi vege, myös suussasulavan herkullinen? Slurps!"
      linkURL="/reseptit"
      linkText="Tämän päivän dinneri"
    />
  </section>
);

export default HVFeatures;
