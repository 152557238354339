const categoryReducer = (state = [], action) => {
  switch (action.type) {
    case 'ADD_CATEGORY': {
      const newState = state.concat(action.data);
      return newState;
    }
    case 'EMPTY_CATEGORIES': {
      return [];
    }
    case 'RESET_FILTERS': {
      const newState = state.map((item) => {
        const newItem = {
          ...item,
          filter: false,
        };
        return newItem;
      });
      return newState;
    }
    case 'SET_FILTER': {
      const newState = state.map((item) => {
        if (item.pk === action.data) {
          const newItem = {
            ...item,
            filter: true,
          };
          return newItem;
        }
        return item;
      });
      return newState;
    }
    case 'REMOVE_FILTER': {
      const newState = state.map((item) => {
        if (item.pk === action.data) {
          const newItem = {
            ...item,
            filter: false,
          };
          return newItem;
        }
        return item;
      });
      return newState;
    }
    default:
      return state;
  }
};

export const addCategory = (data) => (
  {
    type: 'ADD_CATEGORY',
    data,
  }
);

export const emptyCategories = () => (
  {
    type: 'EMPTY_CATEGORIES',
  }
);

export const resetFilters = () => (
  {
    type: 'RESET_FILTERS',
  }
);

export const setFilter = (data) => (
  {
    type: 'SET_FILTER',
    data,
  }
);

export const removeFilter = (data) => (
  {
    type: 'REMOVE_FILTER',
    data,
  }
);

export default categoryReducer;
