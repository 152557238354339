import React from 'react';
import PropTypes from 'prop-types';
import IconError from './icon__alert-error@16x16.svg';

const ErrorMessage = ({ message }) => (
  <div className="form-error flex flex-ai-c">
    <img src={IconError} alt="" />
    <span className="form-error__msg">{ message }</span>
  </div>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorMessage;
