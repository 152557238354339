import React from 'react';
import PropTypes from 'prop-types';
import Ingredient from '../../../common/ingredient/Ingredient';

const RVIngredients = ({
  recipe, ingredients, optionalIngredients,
}) => {
  if (!ingredients) {
    return null;
  }

  return (
    <div className="rv-ingredients">
      <h5 className="text--capitalized">Tarvitset</h5>
      {
        ingredients.map((ingredient) => (
          <Ingredient
            key={ingredient.name}
            name={ingredient.plural}
            amount={Math.round(ingredient.amount * recipe.meals * 100) / 100}
            unit={ingredient.unit}
          />
        ))
      }
      {
        (optionalIngredients.length !== 0)
          ? (
            <>
              <div className="rv-optional-ingredients">
                <h5 className="text--capitalized">Lisäksi</h5>
                {
                  optionalIngredients.map((ingredient) => (
                    <p className="ingredient-name">{ingredient.plural}</p>
                  ))
                }
              </div>
            </>
          )
          : <></>
      }
    </div>
  );
};

RVIngredients.defaultProps = {
  optionalIngredients: [],
};

RVIngredients.propTypes = {
  recipe: PropTypes.instanceOf(Object).isRequired,
  ingredients: PropTypes.instanceOf(Array).isRequired,
  optionalIngredients: PropTypes.instanceOf(Array),
};

export default RVIngredients;
