import React from 'react';
import { useSelector } from 'react-redux';
import SVRecipes from './recipes/SVRecipes';
import SVIngredients from './ingredients/SVIngredients';
import SVPlaceholder from './placeholder/SVPlaceholder';
import recipeSelector from '../../selectors/recipe.selector';

const ShoppingView = () => {
  const shoppingList = useSelector(recipeSelector.getShoppingListRecipes);

  return (
    <section className="sv-bg">
      <div className="sv-content-wrapper">
        {
          shoppingList.length === 0
            ? <SVPlaceholder />
            : (
              <div className="sv-content-container">
                <SVIngredients />
                <SVRecipes />
              </div>
            )
        }
      </div>
    </section>
  );
};

export default ShoppingView;
