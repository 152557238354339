import React from 'react';
import { useSelector } from 'react-redux';
import DVRecipeFilter from './recipe-filter/DVRecipeFilter';
import DVRecipes from './recipes/DVRecipes';
import Loading from '../common/loading/Loading';

const DiscoveryView = () => {
  const loading = useSelector((state) => state.loading);
  return (
    <div className="discovery-view-content">
      <h2 className="text--center">Kaikki reseptit</h2>
      <p className="text--lg text--center">Kokeile yhtä tai useampaa filtteriä 👩🏾‍🍳</p>
      {
        loading
          ? <div className="flex flex-jc-c"><Loading /></div>
          : (
            <>
              <DVRecipeFilter />
              <DVRecipes />
            </>
          )
      }
    </div>
  );
};

export default DiscoveryView;
