import React from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '../../../../icons/HomeIcon';
import MealIcon from '../../../../icons/MealIcon';
import CartIcon from '../../../../icons/CartIcon';
import ShoppingBagItems from '../links/link-shopping-view/ShoppingBagItems';

const MobileNav = () => (
  <nav className="mobile-nav hide-for-tablet-up">
    <div className="mobile-nav__items flex flex-jc-sb">
      <div className="mobile-nav__item">
        <NavLink exact to="/" activeClassName="selected" className="mobile-nav__link flex flex-vertical flex-ai-c">
          <HomeIcon />
          <span className="text--xs text--sb">Koti</span>
        </NavLink>
      </div>
      <div className="mobile-nav__item">
        <NavLink exact to="/reseptit" activeClassName="selected" className="mobile-nav__link flex flex-vertical flex-ai-c">
          <MealIcon />
          <span className="text--xs text--sb">Reseptit</span>
        </NavLink>
      </div>
      <div className="mobile-nav__item mobile-nav__cart flex">
        <NavLink exact to="/ostoslista" activeClassName="selected" className="mobile-nav__link flex flex-vertical flex-ai-c">
          <CartIcon />
          <span className="text--xs text--sb">Ostoslista</span>
        </NavLink>
        <div className="mobile-nav__cart-items">
          <ShoppingBagItems />
        </div>
      </div>
    </div>
  </nav>
);

export default MobileNav;
