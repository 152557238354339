import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import IconMeals from '../../../../images/icon__meals.svg';
import IconInfo from '../../../../images/icon__info.svg';
import IconRemove from '../../../../images/icon__remove.svg';
import {
  addMeals,
  reduceMeals,
  removeFromShoppingList,
} from '../../../../reducers/recipe.reducer';
import IconWithText from '../../../common/icon-with-text/IconWithText';
import ButtonTertiary from '../../../common/buttons/button-tertiary/ButtonTertiary';
import IconPlus from '../../../../images/icon__plus.svg';
import IconMinus from '../../../../images/icon__minus.svg';

const SVRecipeBody = ({ recipe }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(removeFromShoppingList(recipe.pk));
  };

  const addMeal = () => {
    dispatch(addMeals(recipe.pk));
  };

  const subtractMeal = () => {
    if (recipe.meals >= 2) {
      dispatch(reduceMeals(recipe.pk));
    }
  };

  return (
    <div className="collapsible-recipe__body">
      <div className="collapsible-recipe__hidden-row flex flex-jc-sb flex-ai-c">
        <IconWithText iconUrl={IconMeals} text={`${recipe.meals} annosta`} />
        <div className="mealcounter_button-container flex flex-jc-sb">
          <ButtonTertiary handleClick={subtractMeal} image={IconMinus} counter />
          <ButtonTertiary handleClick={addMeal} image={IconPlus} counter />
        </div>
      </div>
      <div className="collapsible-recipe__hidden-row flex flex-jc-sb flex-ai-c">
        <IconWithText iconUrl={IconInfo} text="Reseptin tiedot" />
        <Link className="page-link" to={`reseptit/${recipe.name}`}>
          <ButtonTertiary text="Katso ohje" />
        </Link>
      </div>
      <div className="collapsible-recipe__hidden-row flex flex-jc-sb flex-ai-c">
        <IconWithText iconUrl={IconRemove} text="Poista ostoslistalta" />
        <ButtonTertiary handleClick={handleDelete} text="Poista" />
      </div>
    </div>
  );
};

SVRecipeBody.propTypes = {
  recipe: PropTypes.instanceOf(Object).isRequired,
};

export default SVRecipeBody;
