import React from 'react';
import PropTypes from 'prop-types';

const CustomCheckbox = ({ children, id }) => (
  <label htmlFor={id}>
    <input
      id={id}
      type="checkbox"
      className="ingredient-checkbox"
    />
    { children }
  </label>
);

CustomCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
};

export default CustomCheckbox;
