import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Ingredient from '../../common/ingredient/Ingredient';
import CustomCheckbox from '../../common/custom-checkbox/CustomCheckbox';
import { createSummedShoppingList, emptyShoppingList } from '../../../reducers/recipe.reducer';
import recipeSelector from '../../../selectors/recipe.selector';
import SVIngredientAddition from './SVIngredientAddition';
import CustomIngredient from './SVCustomIngredient';
import { emptyCustomIngredients } from '../../../reducers/customIngredients.reducer';
import ButtonSecondary from '../../common/buttons/button-secondary/ButtonSecondary';

const SVIngredients = () => {
  const dispatch = useDispatch();
  const shoppingList = useSelector(recipeSelector.getShoppingListRecipes);
  const shoppingListIngredients = useSelector(recipeSelector.getShoppingListIngredients);
  const customIngredients = useSelector((state) => state.customIngredients);
  const optionalIngredients = useSelector(recipeSelector.getShoppingListOptionalIngredients);

  const summedShoppingList = shoppingListIngredients.length > 0
    ? createSummedShoppingList(shoppingListIngredients, shoppingList)
    : {};

  const handleEmpty = () => {
    dispatch(emptyShoppingList());
    dispatch(emptyCustomIngredients());
  };

  return (
    <div className="sv-ingredient-container">
      <div className="sv-ingredient-container__header flex flex-jc-sb flex-ai-c">
        <h1 className="sv-h1">Ostoslista</h1>
        {
          shoppingList.length > 0
            ? <ButtonSecondary handleClick={handleEmpty} text="Tyhjennä" />
            : <></>
        }
      </div>
      <p>Kaikkien lisäämiesi reseptien ainekset 👇</p>
      {
        Object.entries(summedShoppingList).map(([key, values]) => (
          <div key={key} className="sv-ingredient-group">
            <h5 className="text--capitalized">{key}</h5>
            {
              values.map((item) => (
                <CustomCheckbox key={`${item.name}-${item.unit}`} id={`${item.name}-${item.unit}`}>
                  <Ingredient
                    name={item.name}
                    amount={Math.round(item.amount * 100) / 100}
                    unit={item.unit}
                  />
                </CustomCheckbox>
              ))
            }
          </div>
        ))
      }
      {
        optionalIngredients.length > 0
          ? (
            <>
              <h5 className="text--capitalized">Lisäksi / Tarjoiluun</h5>
              {
                optionalIngredients.map((item) => (
                  <CustomCheckbox key={item.text}>
                    <div className="ingredient-text">
                      <span className="ingredient-custom">{item.plural}</span>
                    </div>
                  </CustomCheckbox>
                ))
              }
            </>
          )
          : <></>
      }
      {
        customIngredients.length > 0
          ? (
            <>
              <h5 className="text--capitalized">Itse lisätyt ainekset</h5>
              {
                customIngredients.map((item) => (
                  <CustomCheckbox key={item.text} id={item.text}>
                    <CustomIngredient ingredient={item} />
                  </CustomCheckbox>
                ))
              }
            </>
          )
          : <></>
      }
      <div className="sv-add-ingredient">
        <SVIngredientAddition />
      </div>
    </div>
  );
};

export default SVIngredients;
