import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const RecipeCard = ({ recipe }) => (
  <div className="recipe-card">
    <Link to={`/reseptit/${recipe.name}`}>
      <div className="recipe-card__image-container">
        <div
          className="recipe-card__image"
          style={{
            backgroundImage: `url(${recipe.imageSmall})`,
          }}
        />
      </div>
      <div className="recipe-card__content">
        <p className="recipe-card__name">{recipe.name}</p>
        <div className="recipe-card__chip">
          <p className="recipe-card__chip-text">{`${recipe.duration} min`}</p>
        </div>
      </div>
    </Link>
  </div>
);

RecipeCard.propTypes = {
  recipe: PropTypes.instanceOf(Object).isRequired,
};

export default RecipeCard;
