import { React, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LinkHome from './links/link-home-view/LinkHome';
import LinkDiscoveryView from './links/link-discovery-view/LinkDiscoveryView';
import LinkReturnToDiscovery from './links/link-discovery-view/LinkReturnToDiscovery';
import LinkShoppingView from './links/link-shopping-view/LinkShoppingView';

const Navigation = () => {
  const currentPath = useLocation().pathname;
  const isHomeView = currentPath.includes('/') && currentPath.length === 1;
  const isRecipeView = currentPath.includes('reseptit') && currentPath.length > 9;
  const isNotRecipeAdditionView = !currentPath.includes('lisaaresepti');
  const navBg = isHomeView || isRecipeView ? 'nav--transparent' : 'nav--light-bg';
  const [nav, setNav] = useState(false);

  const changeBackground = () => {
    if (isNotRecipeAdditionView && window.scrollY >= 70) {
      setNav(true);
    } else {
      setNav(false);
    }
  };

  window.addEventListener('scroll', changeBackground);

  return (
    <nav className={`${nav ? 'nav scroll' : 'nav'} ${navBg}`}>
      <div className="nav__content flex flex-jc-sb flex-ai-c">
        <div className="nav__content--left">
          {
            isRecipeView
              ? <LinkReturnToDiscovery />
              : <LinkHome />
          }
        </div>
        <div className="nav__content--right flex flex-jc-sb flex-ai-c">
          <LinkDiscoveryView />
          <LinkShoppingView />
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
