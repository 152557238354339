import React from 'react';

const RAVSideNav = () => (
  <div className="side-nav hide-for-tablet-down">
    <h4 className="side-nav__heading">Lisää resepti</h4>
    <ul className="side-nav__list">
      <li className="side-nav__item">
        <a className="side-nav__link" href="#basics-heading">Reseptin perustiedot</a>
      </li>
      <li className="side-nav__item">
        <a className="side-nav__link" href="#ingredients-heading">Ainekset</a>
      </li>
      <li className="side-nav__item">
        <a className="side-nav__link" href="#instructions-heading">Ohjeet</a>
      </li>
      <li className="side-nav__item">
        <a className="side-nav__link" href="#recipe-addition">Takaisin ylös ↑</a>
      </li>
    </ul>
  </div>
);

export default RAVSideNav;
