import React from 'react';
import { Link } from 'react-router-dom';
import ArrowLeftIcon from '../../../../../icons/ArrowLeftIcon';

const LinkReturnToDiscovery = () => (
  <div className="flex flex-jc-sb flex-ai-c">
    <ArrowLeftIcon />
    <Link className="page-link nav__link nav__link--white" to="/reseptit">Takaisin resepteihin</Link>
  </div>
);

export default LinkReturnToDiscovery;
