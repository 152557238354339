import React from 'react';

const MealIcon = () => (
  <svg
    viewBox="0 0 20 20"
    height="20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(1.4285714285714286,0,0,1.4285714285714286,0,0)">
      <path
        d="M14,3.75A3.531,3.531,0,0,0,10.75,0,3.531,3.531,0,0,0,7.5,3.75,3.714,3.714,0,0,0,9.831,7.337.251.251,0,0,1,10,7.574V13.25a.75.75,0,0,0,1.5,0V7.574a.251.251,0,0,1,.169-.237A3.714,3.714,0,0,0,14,3.75Z"
        fill="currentColor"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0"
      />
      <path
        d="M5.75,0A.75.75,0,0,0,5,.75v2.5a1.732,1.732,0,0,1-.584,1.3.249.249,0,0,1-.268.042A.252.252,0,0,1,4,4.36V.75a.75.75,0,0,0-1.5,0V4.36a.252.252,0,0,1-.148.229.249.249,0,0,1-.268-.042A1.732,1.732,0,0,1,1.5,3.25V.75A.75.75,0,0,0,0,.75v2.5A3.249,3.249,0,0,0,2.322,6.356.251.251,0,0,1,2.5,6.6V13.25a.75.75,0,0,0,1.5,0V6.6a.251.251,0,0,1,.178-.24A3.249,3.249,0,0,0,6.5,3.25V.75A.75.75,0,0,0,5.75,0Z"
        fill="currentColor"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0"
      />
    </g>
  </svg>
);

export default MealIcon;
