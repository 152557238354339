import React from 'react';
import { useSelector } from 'react-redux';
import recipeSelector from '../../../../../selectors/recipe.selector';

const ShoppingBagItems = () => {
  const shoppingList = useSelector(recipeSelector.getShoppingListRecipes);
  const bagItemsStyle = shoppingList.length === 0 ? 'nav__shopping-bag-items' : 'nav__shopping-bag-items nav__shopping-bag-items--added';

  return (
    <div className={bagItemsStyle}>
      <p>{shoppingList.length}</p>
    </div>
  );
};

export default ShoppingBagItems;
