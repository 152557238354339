import React from 'react';
import { Link } from 'react-router-dom';
import ShoppingBagIcon from '../../../../../icons/ShoppingBagIcon';
import ShoppingBagItems from './ShoppingBagItems';

const LinkShoppingView = () => (
  <Link className="page-link nav__link hide-for-mobile flex flex-jc-c flex-ai-c" to="/ostoslista">
    Ostoslista
    <div className="nav__shopping-bag-wrapper flex flex-jc-c flex-ai-c">
      <div className="nav__shopping-bag-container flex">
        <ShoppingBagIcon />
        <ShoppingBagItems />
      </div>
    </div>
  </Link>
);

export default LinkShoppingView;
