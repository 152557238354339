import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import RecipeView from '../recipe-view/RecipeView';
import ShoppingView from '../shopping-view/ShoppingView';
import RecipeAdditionView from '../recipe-addition/RecipeAdditionView';
import HomeView from '../home/HomeView';
import DiscoveryView from '../recipe-discovery/DiscoveryView';

const RouteSwitcher = () => {
  const recipes = useSelector((state) => state.recipes);
  const match = useRouteMatch('/reseptit/:id');
  const recipe = match
    ? recipes.find((item) => (
      item.name === match.params.id
    ))
    : null;

  return (
    <Switch>
      <Route exact path="/reseptit">
        <DiscoveryView />
      </Route>
      <Route path="/reseptit/:id">
        {
          recipe
            ? <RecipeView recipe={recipe} />
            : <></>
        }
      </Route>
      <Route path="/ostoslista">
        <ShoppingView />
      </Route>
      <Route path="/lisaaresepti">
        <RecipeAdditionView />
      </Route>
      <Route path="/">
        <HomeView />
      </Route>
    </Switch>
  );
};

export default RouteSwitcher;
