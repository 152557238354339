import React from 'react';
import PropTypes from 'prop-types';

const CustomInput = ({
  name,
  title,
  children,
  value,
  handleChange,
  placeholder,
  submitted,
  required,
  type,
  min,
  step,
}) => (
  <label htmlFor={name} className="recipe-addition-form__item flex flex-vertical">
    {title}
    {children}
    <input
      type={type}
      name={name}
      id={name}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      required={required}
      className={submitted && !value ? 'input-error' : null}
      min={min}
      step={step}
    />
  </label>
);

CustomInput.defaultProps = {
  min: NaN,
  step: NaN,
};

CustomInput.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.instanceOf(Function).isRequired,
  placeholder: PropTypes.string.isRequired,
  submitted: PropTypes.bool.isRequired,
  required: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  min: PropTypes.string,
  step: PropTypes.number,
};

export default CustomInput;
