import React from 'react';

const ShoppingBagIcon = () => (
  <svg
    viewBox="0 0 24 24"
    height="24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M8.982,11.517a.375.375,0,1,1-.375.375.375.375,0,0,1,.375-.375"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M14.982,11.517a.375.375,0,1,1-.375.375.375.375,0,0,1,.375-.375"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M16.188,16.5a5.064,5.064,0,0,1-8.42,0"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M19.049,6.75H4.936A1.4,1.4,0,0,0,3.5,7.907l-2,13.915A1.357,1.357,0,0,0,2.937,23.25H21.048a1.356,1.356,0,0,0,1.436-1.428l-2-13.915A1.4,1.4,0,0,0,19.049,6.75Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M15.662,3.807A3.765,3.765,0,0,0,11.976.75h0A3.763,3.763,0,0,0,8.3,3.788"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export default ShoppingBagIcon;
