import React from 'react';
import PropTypes from 'prop-types';

const IconWithText = ({ iconUrl, text, link }) => (
  <div className="icon__container flex flex-ai-c">
    <img className="icon" src={iconUrl} alt="" />
    {
      link
        ? <a className="icon__text--link" href={link} target="blank">{text}</a>
        : <p className="icon__text">{text}</p>
    }
  </div>
);

IconWithText.propTypes = {
  iconUrl: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
};

IconWithText.defaultProps = {
  link: '',
};

export default IconWithText;
