import React from 'react';
import PropTypes from 'prop-types';
import CustomInput from '../../../common/inputs/CustomInput';
import CustomSelect from '../../../common/inputs/CustomSelect';

const RAVIngredient = ({
  num, options, values, setValues, submitted,
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const res = name.split('-');
    const attr = res[1];
    const { ingredientLinks } = values;
    ingredientLinks[num][attr] = value;
    setValues({
      ...values,
      ingredientLinks,
    });
  };

  const handleChange = (selected) => {
    const { ingredientLinks } = values;
    ingredientLinks[num].sk = selected.sk;
    ingredientLinks[num].name = selected.name;
    setValues({
      ...values,
      ingredientLinks,
    });
  };

  return (
    <div id="ingredient-container">
      <div className="recipe-addition-form__ingredient flex flex-vertical">
        Ainesosan nimi
        <CustomSelect
          id={`Ainesosat${num}`}
          placeholder="Valitse..."
          handleChange={handleChange}
          options={options}
        />
      </div>
      <CustomInput
        name={`ingredient-amount-${num}`}
        title="Määrä"
        value={values.ingredientLinks[num].amount}
        handleChange={handleInputChange}
        placeholder={2}
        min="0"
        required="true"
        type="number"
        submitted={submitted}
        step="0.5"
      />
      <CustomInput
        name={`ingredient-unit-${num}`}
        title="Yksikkö"
        value={values.ingredientLinks[num].unit}
        handleChange={handleInputChange}
        placeholder="kpl"
        required="true"
        type="text"
        submitted={submitted}
      />
    </div>
  );
};

RAVIngredient.propTypes = {
  num: PropTypes.number.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  setValues: PropTypes.instanceOf(Function).isRequired,
  submitted: PropTypes.bool.isRequired,
};

export default RAVIngredient;
