import React from 'react';
import PropTypes from 'prop-types';
import RVStep from './step/RVStep';

const RVInstructions = ({ recipe, ingredients }) => {
  const getIngredients = (step) => {
    if (ingredients) {
      return ingredients.filter((ingredient) => ingredient.steps.includes(step));
    }
    return [];
  };

  return (
    <div className="recipe-view-instructions">
      {
        recipe.instructions.map((step) => (
          <RVStep
            key={step.step}
            step={step}
            ingredients={getIngredients(step.step)}
            meals={recipe.meals}
          />
        ))
      }
    </div>
  );
};

RVInstructions.propTypes = {
  recipe: PropTypes.instanceOf(Object).isRequired,
  ingredients: PropTypes.instanceOf(Array).isRequired,
};

export default RVInstructions;
