import React from 'react';

const Footer = () => (
  <footer className="footer">
    <div className="footer__content flex">
      <span className="copyright"> &copy; 2021 Slurps 🌍 💙  • </span>
      <a className="copyright link--secondary" href="https://tally.so/r/3xMEdw" target="blank">Palautetta tästä sivusta?</a>
    </div>
  </footer>
);

export default Footer;
