import React from 'react';
import PropTypes from 'prop-types';

const RVStep = ({ step, ingredients, meals }) => (
  <div className="instruction-step">
    <h5 className="instruction-step__name text--capitalized">{`Vaihe ${step.step}`}</h5>
    {
      ingredients.length > 0
        ? (
          <div>
            <p className="instruction-step__heading">Ainekset:</p>
            <ul>
              {
                ingredients.map((ingredient) => (<li className="instruction-step__info" key={ingredient.sk}>{`${ingredient.plural} ${Math.round(ingredient.amount * meals * 100) / 100} ${ingredient.unit} `}</li>))
              }
            </ul>
          </div>
        )
        : <></>
    }
    <p className="instruction-step__heading">Tee näin:</p>
    <p className="instruction-step__info">
      {step.description}
    </p>
  </div>
);

RVStep.propTypes = {
  step: PropTypes.instanceOf(Object).isRequired,
  ingredients: PropTypes.instanceOf(Array).isRequired,
  meals: PropTypes.number.isRequired,
};

export default RVStep;
