import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addIngredient } from '../../../reducers/customIngredients.reducer';
import ButtonPrimary from '../../common/buttons/button-primary/ButtonPrimary';
import ButtonSecondary from '../../common/buttons/button-secondary/ButtonSecondary';

const SVIngredientAddition = () => {
  const [newIngredient, setNewIngredient] = useState('');
  const [visibility, setVisibility] = useState(false);

  const showWhenVisible = { display: visibility ? '' : 'none' };
  const hideWhenVisible = { display: visibility ? 'none' : '' };

  const toggleVisibility = () => {
    setVisibility(!visibility);
  };
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    const id = Math.floor(Math.random() * 10000000);
    event.preventDefault();
    const newItem = {
      text: newIngredient,
      id,
    };
    dispatch(addIngredient(newItem));
    setNewIngredient('');
  };

  const handleIngredientChange = (event) => {
    event.preventDefault();
    setNewIngredient(event.target.value);
  };

  return (
    <>
      <div style={hideWhenVisible}>
        <ButtonPrimary handleClick={toggleVisibility} text="+ Lisää ostoslistalle" />
      </div>
      <div style={showWhenVisible}>
        <form className="add-ingredient-form" onSubmit={handleSubmit}>
          <div className="flex flex-vertical">
            <label htmlFor="ingredient-name" className="add-ingredient-form__label flex flex-vertical">
              Mitä haluat lisätä?
              <input
                type="text"
                name="ingredient-name"
                id="ingredient-name"
                value={newIngredient}
                placeholder="esim. 2 munakoisoa 🍆"
                onChange={handleIngredientChange}
                required
              />
            </label>
          </div>
          <div className="add-ingredient-form__btn-container flex flex-jc-sb">
            <ButtonPrimary type="submit" text="+ Lisää" />
            <ButtonSecondary handleClick={toggleVisibility} text="Sulje" />
          </div>
        </form>
      </div>
    </>
  );
};

export default SVIngredientAddition;
