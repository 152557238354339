import React from 'react';
import PropTypes from 'prop-types';
import UploadIcon from '../../../images/icon__upload.svg';
import ErrorMessage from '../../common/error-message/ErrorMessage';

const RAVImageUpload = (props) => {
  const { values, setValues, submitted } = props;
  const handleChange = (event) => {
    const file = event.target.files[0];
    setValues({
      ...values,
      image: file,
    });
  };

  return (
    <div className="recipe-addition-form__item">
      <p className="recipe-addition-form__subheading">Lataa kuva</p>
      <p className="text--sm">✔ Valitse suurikokoinen leveä kuva, mielellään 2400x1600px.</p>
      <p className="text--sm">
        ✔ Kompressoi kuva (käytä esim.&nbsp;
        <a href="https://tinyjpg.com/" target="blank" className="link--general">TinyJPG:tä</a>
        &nbsp;tai&nbsp;
        <a href="https://photoshop.adobe.com/" target="blank" className="link--general">Photoshop Expressiä</a>
        ).
      </p>
      <p className="text--sm">✔ Konvertoi tarvittaessa JPEG:ksi.</p>
      {submitted && Number.isNaN(values.image)
        ? <ErrorMessage message="Reseptillä on oltava kuva." />
        : <p className="form-hint">Jaa ohjeet sopiviin kokonaisuuksiin ja valitse kussakin vaiheessa tarvittavat ainekset. Tarkistathan oikeinkirjoituksen 🤓</p>}
      <label htmlFor="upload-photo" className="file-upload-btn flex flex-jc-c flex-ai-c">
        <img id="upload-photo__icon" src={UploadIcon} alt="" />
        Lataa reseptin kuva
        <input
          id="upload-photo"
          type="file"
          name="image"
          accept=".jpg, .jpeg"
          onChange={handleChange}
          required
        />
      </label>
      {
        values.image
          ? <img src={URL.createObjectURL(values.image)} alt="" />
          : <></>
      }
    </div>
  );
};

RAVImageUpload.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  setValues: PropTypes.instanceOf(Function).isRequired,
  submitted: PropTypes.bool.isRequired,
};

export default RAVImageUpload;
