import React from 'react';
import PropTypes from 'prop-types';

const ButtonSecondary = ({
  handleClick,
  type,
  text,
  image,
  size,
  disabled,
  isNeutral,
}) => {
  const styles = {
    backgroundImage: `url(${image})`,
    border: isNeutral ? '2px solid #18191F' : '',
    color: isNeutral ? '#18191F' : '',
  };

  return (
    <button
      style={styles}
      className={`btn btn--secondary ${size}`}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={handleClick}
      disabled={disabled}
    >
      { text }
    </button>
  );
};

ButtonSecondary.defaultProps = {
  type: 'button',
  image: null,
  size: 'btn--md',
  disabled: false,
  isNeutral: false,
  text: '',
};

ButtonSecondary.propTypes = {
  handleClick: PropTypes.instanceOf(Function).isRequired,
  type: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  isNeutral: PropTypes.bool,
};

export default ButtonSecondary;
