import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import RecipeCard from '../../common/recipe-card/RecipeCard';
import categorySelector from '../../../selectors/category.selector';

const DVRecipes = () => {
  let recipes = useSelector((state) => state.recipes);
  recipes = _.sortBy(recipes, 'name');
  const filters = useSelector(categorySelector.getCategoryFilters);
  const timePKS = ['category#8734427', 'category#8661172'];
  const regularFilters = filters.filter((item) => !timePKS.includes(item));
  const timeFilters = filters.filter((item) => timePKS.includes(item));
  const filterRegular = (recipe) => (
    regularFilters.length === 0 || regularFilters.every((filt) => recipe.categories.includes(filt))
  );

  const filterTime = (recipe) => {
    if (timeFilters.includes(timePKS[0])) {
      return Number(recipe.duration) <= 30;
    }
    if (timeFilters.includes(timePKS[1])) {
      return Number(recipe.duration) <= 60;
    }
    return true;
  };

  const filterRecipes = (recipe) => {
    if (filters.length === 0) {
      return <RecipeCard key={recipe.pk} recipe={recipe} />;
    }
    if (filterTime(recipe) && filterRegular(recipe)) {
      return <RecipeCard key={recipe.pk} recipe={recipe} />;
    }
    return <Fragment key={recipe.pk} />;
  };

  return (
    <section className="recipe-listing" id="recipe-listing">
      <div className="recipe-listing_container">
        {
          recipes.map((recipe) => (
            filterRecipes(recipe)
          ))
        }
      </div>
    </section>
  );
};

export default DVRecipes;
