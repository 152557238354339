import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ButtonPrimary from '../../common/buttons/button-primary/ButtonPrimary';
import ButtonSecondary from '../../common/buttons/button-secondary/ButtonSecondary';

const RAVCategories = ({ values, setValues }) => {
  const categories = useSelector((state) => state.categories);

  const handleClick = (pk) => {
    if (values.categories.includes(pk)) {
      const newCategories = values.categories.filter((item) => item !== pk);
      setValues({
        ...values,
        categories: newCategories,
      });
    } else {
      const newCategories = values.categories.concat(pk);
      setValues({
        ...values,
        categories: newCategories,
      });
    }
  };

  return (
    <>
      <p className="recipe-addition-form__subheading">Valitse kategoriat</p>
      <div className="category-container flex flex-jc-sb flex-jc-c">
        {
          categories.map((category) => {
            if (values.categories.includes(category.pk)) {
              return (
                <ButtonPrimary
                  text={category.name}
                  handleClick={() => handleClick(category.pk)}
                  size="btn--xs"
                />
              );
            }
            if (category.class !== 'Aika') {
              return (
                <ButtonSecondary
                  text={category.name}
                  handleClick={() => handleClick(category.pk)}
                  size="btn--xs"
                />
              );
            }
            return <></>;
          })
        }
      </div>
    </>
  );
};

RAVCategories.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  setValues: PropTypes.instanceOf(Function).isRequired,
};

export default RAVCategories;
