import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../../common/error-message/ErrorMessage';
import CustomInput from '../../common/inputs/CustomInput';

const RAVBasicInfo = ({ values, setValues, submitted }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <>
      <h2 className="recipe-addition-form__heading" id="basics-heading">Reseptin perustiedot</h2>
      <CustomInput
        name="name"
        title="Reseptin nimi"
        value={values.name}
        handleChange={handleInputChange}
        submitted={submitted}
        required="true"
        placeholder="Ratatouille"
        type="text"
      >
        {submitted && !values.name
          ? <ErrorMessage message="Reseptillä täytyy olla nimi." />
          : <p className="form-hint">Ensimmäinen sana isolla kirjaimella, muut pienellä (ellei erisnimi).</p>}
      </CustomInput>
      <CustomInput
        name="description"
        title="Reseptin kuvaus"
        value={values.description}
        handleChange={handleInputChange}
        submitted={submitted}
        required="true"
        placeholder="Ranskalainen klassikko, joka sulattaa kovimmankin kriitikon sydämen."
        type="text"
      >
        {submitted && !values.description
          ? <ErrorMessage message="Reseptillä täytyy olla nimi." />
          : <p className="form-hint">Yksi lause, piste loppuun.</p>}
      </CustomInput>
      <CustomInput
        name="chef"
        title="Reseptin kokki (valinnainen)"
        value={values.chef}
        handleChange={handleInputChange}
        submitted={submitted}
        required="false"
        placeholder="Auguste Gusteau"
        type="text"
      >
        <p className="form-hint">Lisää, jos kokki tiedossa ja hän haluaa julkaista nimensä.</p>
      </CustomInput>
      <CustomInput
        name="website"
        title="Linkki reseptiin (valinnainen)"
        value={values.website}
        handleChange={handleInputChange}
        submitted={submitted}
        required="false"
        placeholder="https://gusteaus.com/ratatouille"
        type="text"
      >
        <p className="form-hint">Varmista, että linkki toimii.</p>
      </CustomInput>
    </>
  );
};

RAVBasicInfo.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  setValues: PropTypes.instanceOf(Function).isRequired,
  submitted: PropTypes.bool.isRequired,
};

export default RAVBasicInfo;
