import React from 'react';
import { Link } from 'react-router-dom';
import ButtonPrimary from '../../common/buttons/button-primary/ButtonPrimary';
import PlaceHolderImage from '../../../images/empty_shoppinglist.png';

const SVPlaceholder = () => (
  <div className="sv-placeholder-container">
    <h2>Ostoslista</h2>
    <p className="shopping-placeholder-text">
      {
        `
        Ostoslistasi on tällä hetkellä tyhjä. 
        Voit lisätä kaikki haluamasi reseptit tänne ja 
        muunnamme ne kätevästi yhdeksi listaksi, 
        jotta kaupassakäyntisi olisi sujuvaa ☺️
        `
      }
    </p>
    <Link className="page-link" to="/reseptit">
      <ButtonPrimary text="Katso kaikki reseptit" size="btn--lg" />
    </Link>
    <img src={PlaceHolderImage} alt="" />
  </div>
);

export default SVPlaceholder;
