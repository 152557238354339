import React from 'react';
import PropTypes from 'prop-types';
import CustomSelect from '../../common/inputs/CustomSelect';

const RAVStep = ({
  num, values, setValues, submitted,
}) => {
  const { instructions } = values;
  const ingredients = values.ingredientLinks.map((item) => {
    const newItem = {
      sk: item.sk,
      name: item.name,
      value: `${item.amount} ${item.unit} ${item.name}`,
      label: `${item.amount} ${item.unit} ${item.name}`,
    };
    return newItem;
  });
  const optionalIngredients = values.optionalIngredients.map((item) => (
    {
      name: item.name,
      sk: item.sk,
      value: item.name,
      label: item.name,
    }
  ));
  const options = ingredients.concat(optionalIngredients);

  const handleInputChange = (e) => {
    const { value } = e.target;
    instructions[num - 1].description = value;
    setValues({
      ...values,
      instructions,
    });
  };

  const updateIngredientSteps = (item, ids) => {
    if (ids.includes(item.sk) && !item.steps.includes(num)) {
      const newItem = {
        ...item,
        steps: item.steps.concat(num),
      };
      return newItem;
    }

    if (!ids.includes(item.sk) && item.steps.includes(num)) {
      const newItem = {
        ...item,
        steps: item.steps.filter((step) => step !== num),
      };
      return newItem;
    }
    return item;
  };

  const handleChange = (selected) => {
    const ids = selected.map((item) => item.sk);
    const newIngredientLinks = values.ingredientLinks.map((item) => (
      updateIngredientSteps(item, ids)
    ));
    setValues({
      ...values,
      ingredientLinks: newIngredientLinks,
    });
  };

  return (
    <div id="instructions-container">
      <label htmlFor={`Vaihe${num}`} className="flex flex-vertical" id="step">
        {`Vaihe ${num}`}
        <textarea
          rows="6"
          cols="150"
          id={`Vaihe${num}`}
          name="step-description"
          value={instructions[num - 1].description}
          onChange={handleInputChange}
          required
          className={submitted && !instructions[num - 1].description ? 'input-error' : null}
        />
      </label>
      <div id="ingredient-select">
        <span>Vaiheen ainekset (valinnainen)</span>
        <CustomSelect
          id={`Ainesosat${num}`}
          placeholder="Valitse..."
          handleChange={handleChange}
          isMulti="true"
          options={options}
        />
      </div>
    </div>
  );
};

RAVStep.propTypes = {
  num: PropTypes.number.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  setValues: PropTypes.instanceOf(Function).isRequired,
  submitted: PropTypes.bool.isRequired,
};

export default RAVStep;
