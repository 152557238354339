const customIngredientReducer = (state = [], action) => {
  switch (action.type) {
    case 'ADD__CUSTOM_INGREDIENT': {
      const newState = [...state, action.data];
      return newState;
    }
    case 'EMPTY__CUSTOM_INGREDIENTS': {
      const newState = [];
      return newState;
    }
    default: {
      return state;
    }
  }
};

export const addIngredient = (data) => (
  {
    type: 'ADD__CUSTOM_INGREDIENT',
    data,
  }
);

export const emptyCustomIngredients = () => (
  {
    type: 'EMPTY__CUSTOM_INGREDIENTS',
  }
);

export default customIngredientReducer;
