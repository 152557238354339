import { combineReducers, createStore } from 'redux';
import recipeReducer from './recipe.reducer';
import customIngredientReducer from './customIngredients.reducer';
import categoryReducer from './category.reducer';
import loadingReducer from './loading.reducer';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};

const reducer = combineReducers({
  recipes: recipeReducer,
  categories: categoryReducer,
  customIngredients: customIngredientReducer,
  loading: loadingReducer,
});

const store = createStore(reducer, loadState());

store.subscribe(() => saveState(store.getState()));

export default store;
