import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const CustomSelect = ({
  id,
  placeholder,
  handleChange,
  options,
  isMulti,
}) => {
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      maxWidth: '480px',
      minWidth: '200px',
      border: '2px solid #212529',
      padding: '16',
      margin: '10px 0',
      borderRadius: '4',
      fontSize: '0.875rem',
      backgroundColor: '#fefefe',
      outline: state.isFocused ? '4px solid #ffbf47' : 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'rgba(255, 191, 71, 0.5)' : 'none',
      fontSize: '0.825rem',
      fontWeight: '500',
      padding: 10,

      ':active': {
        backgroundColor: 'rgba(255, 191, 71, 0.5)',
      },
    }),
    multiValue: () => ({
      backgroundColor: 'rgba(255, 191, 71, 0.5)',
      display: 'flex',
      fontSize: 12,
      borderRadius: 20,
      marginRight: 5,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }),
    multiValueLabel: () => ({
      backgroundColor: 'rgba(255, 191, 71, 0.5)',
      color: '#111',
      padding: '5px 0px 5px 10px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }),
    multiValueRemove: () => ({
      backgroundColor: 'rgba(255, 191, 71, 0.5)',
      display: 'flex',
      alignItems: 'center',
      padding: 5,
      ':hover': {
        backgroundColor: '#ffbf47',
      },
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 14,
      display: 'flex',
    }),
  };

  return (
    <Select
      aria-labelledby="aria-label"
      inputId={id}
      name={id}
      placeholder={placeholder}
      onChange={handleChange}
      options={options}
      styles={customStyles}
      isMulti={isMulti}
    />
  );
};

CustomSelect.defaultProps = {
  placeholder: '',
  isMulti: false,
};

CustomSelect.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.instanceOf(Function).isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  isMulti: PropTypes.bool,
};

export default CustomSelect;
