import React from 'react';
import { Link } from 'react-router-dom';

const LinkDiscoveryView = () => (
  <Link to="/reseptit" className="page-link nav__link hide-for-mobile">
    Reseptit
  </Link>
);

export default LinkDiscoveryView;
