import React, { Fragment, useEffect } from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './App.scss';
import Navigation from './components/common/navigation/Navigation';
import MobileNav from './components/common/navigation/mobile-nav/MobileNav';
import Footer from './components/common/footer/Footer';
import ScrollToTop from './components/common/ScrollToTop';
import {
  addRecipe,
  emptyRecipes,
  setIngredients,
  setOptionalIngredients,
} from './reducers/recipe.reducer';
import {
  addCategory,
  emptyCategories,
} from './reducers/category.reducer';
import recipeSelector from './selectors/recipe.selector';
import recipeService from './services/recipe.service';
import RouteSwitcher from './components/common/RouteSwitcher';
import { setLoadingFalse, setLoadingTrue } from './reducers/loading.reducer';

const App = () => {
  const dispatch = useDispatch();
  const shoppingList = useSelector(recipeSelector.getShoppingListRecipes);
  const loading = useSelector((state) => state.loading);

  useEffect(() => {
    const fetchRecipes = async () => {
      const newRecipes = await recipeService.getAll();
      await newRecipes.forEach((recipe) => (
        dispatch(addRecipe(recipe))
      ));
      return newRecipes;
    };

    const fetchIngredients = async (pk) => {
      const response = await recipeService.getRecipeIngredients(pk);
      return response;
    };

    const fetchOptionalIngredients = async (batch) => {
      const response = await recipeService
        .getIngredientBatch(batch);
      return response;
    };

    const fetchRecipesAndIngredients = async () => {
      const recipes = await fetchRecipes();
      await recipes.forEach(async (item) => {
        const ingredients = await fetchIngredients(item.pk);
        dispatch(setIngredients(item.pk, ingredients));
        if (item.optionalIngredients) {
          const optionalIngredients = await fetchOptionalIngredients(item.optionalIngredients);
          dispatch(setOptionalIngredients(item.pk, optionalIngredients));
        }
      });
    };

    const fetchCategories = async () => {
      const newCategories = await recipeService.getCategories();
      newCategories.forEach((category) => (
        dispatch(addCategory(category))
      ));
    };

    const fetchData = async () => {
      dispatch(setLoadingTrue());
      await Promise.all([fetchRecipesAndIngredients(), fetchCategories()]);
      dispatch(setLoadingFalse());
    };

    const emptyData = async () => {
      dispatch(emptyCategories());
      dispatch(emptyRecipes());
    };

    if (shoppingList.length === 0) {
      emptyData();
      fetchData();
    }
  }, []);

  return (
    <Router component={Navigation}>
      <>
        <ScrollToTop />
        <Navigation />
        <RouteSwitcher />
        <MobileNav />
      </>
      {
        loading
          ? <></>
          : <Footer component={Footer} />
      }
    </Router>
  );
};

export default App;
