import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import CategoryCard from '../../common/category-card/CategoryCard';

const HVCategories = () => {
  let categories = useSelector((state) => state.categories);
  categories = _.sortBy(categories, 'name');

  const filterTopCategories = (category) => {
    if (category.isTopCategory) {
      return <CategoryCard key={category.pk} category={category} />;
    }
    return <></>;
  };

  const filterByCuisine = (category) => {
    if (category.class === 'Keittiö') {
      return <CategoryCard key={category.pk} category={category} />;
    }
    return <></>;
  };

  return (
    <section className="categories-section">
      <div className="category-listing-wrapper">
        <div className="categories-header">
          <h2 className="categories-header__text">Kasvisreseptejä joka hetkeen</h2>
        </div>
        <div className="category-listing-container">
          {
            categories.map((item) => (
              filterTopCategories(item)
            ))
          }
        </div>
      </div>
      <div className="category-listing-wrapper">
        <div className="categories-header">
          <h2 className="categories-header__text">Makuja maailmalta, vegenä</h2>
        </div>
        <div className="category-listing-container">
          {
            categories.map((item) => (
              filterByCuisine(item)
            ))
          }
        </div>
      </div>
    </section>
  );
};

export default HVCategories;
