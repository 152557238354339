import React from 'react';
import { useSelector } from 'react-redux';
import HVHero from './hero/HVHero';
import HVCategories from './categories/HVCategories';
import HVFeatures from './features/HVFeatures';
import HVFeedback from './feedback/HVFeedback';
import Loading from '../common/loading/Loading';

const HomeView = () => {
  const loading = useSelector((state) => state.loading);
  return (
    <>
      <HVHero />
      {
        loading
          ? <div style={{ 'margin-top': '50px' }} className="flex flex-jc-c"><Loading /></div>
          : (
            <>
              <HVCategories />
              <HVFeatures />
              <HVFeedback />
            </>
          )
      }
    </>
  );
};

export default HomeView;
