import React from 'react';
import PropTypes from 'prop-types';

const RVHero = ({ recipe }) => (
  <section className="recipe-view-hero_container">
    <img className="recipe-view-hero_image" src={recipe.image} alt="" />
    <div className="recipe-view-hero_overlay" />
    <div className="recipe-view-hero_banner">
      <h1>{ recipe.name }</h1>
      <p>{ recipe.description }</p>
    </div>
  </section>
);

RVHero.propTypes = {
  recipe: PropTypes.instanceOf(Object).isRequired,
};

export default RVHero;
