import React from 'react';
import { useSelector } from 'react-redux';
import recipeSelector from '../../../selectors/recipe.selector';
import Hero from '../hero/SVHero';
import SVRecipe from './recipe/SVRecipe';

const SVRecipes = () => {
  const shoppingList = useSelector(recipeSelector.getShoppingListRecipes);
  const a = 1;

  return (
    <div className="sv-recipe-container">
      <Hero a={a} />
      {
        shoppingList.map((recipe) => (
          <SVRecipe key={recipe.pk} recipe={recipe} />
        ))
      }
    </div>
  );
};

export default SVRecipes;
