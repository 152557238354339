const loadingReducer = (state = true, action) => {
  switch (action.type) {
    case 'SET_LOADING_TRUE':
      return true;
    case 'SET_LOADING_FALSE':
      return false;
    default:
      return state;
  }
};

export const setLoadingTrue = () => (
  {
    type: 'SET_LOADING_TRUE',
  }
);

export const setLoadingFalse = () => (
  {
    type: 'SET_LOADING_FALSE',
  }
);

export default loadingReducer;
