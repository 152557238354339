import React from 'react';

const ArrowLeftIcon = () => (
  <svg
    viewBox="0 0 24 24"
    height="24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M23.25 12.497L0.75 12.497"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M4.5 16.247L0.75 12.497 4.5 8.747"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export default ArrowLeftIcon;
