const getCategoryFilters = ({ categories }) => (
  categories.reduce((acc, cur) => {
    if (cur.filter) {
      return acc.concat(cur.pk);
    }
    return acc;
  }, [])
);

export default { getCategoryFilters };
