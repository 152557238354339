import React from 'react';
import PropTypes from 'prop-types';

const ButtonTertiary = ({
  handleClick,
  type,
  image,
  text,
  size,
  counter,
  disabled,
}) => {
  const styles = {
    backgroundImage: `url(${image})`,
    width: counter ? '40px' : '',
    height: counter ? '40px' : '',
    borderRadius: counter ? '10px' : '',
  };

  return (
    <button
      style={styles}
      className={`btn btn--tercery ${size}`}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={handleClick}
      disabled={disabled}
    >
      { text }
    </button>
  );
};

ButtonTertiary.defaultProps = {
  type: 'button',
  image: null,
  size: 'btn--sm',
  counter: false,
  disabled: false,
  text: '',
  handleClick: null,
};

ButtonTertiary.propTypes = {
  handleClick: PropTypes.instanceOf(Function),
  type: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
  counter: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ButtonTertiary;
