import React from 'react';

const HomeIcon = () => (
  <svg
    viewBox="0 0 20 20"
    height="20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(1.4285714285714286,0,0,1.4285714285714286,0,0)">
      <path
        d="M13.854,6.646l-6.5-6.5a.5.5,0,0,0-.708,0l-6.5,6.5A.5.5,0,0,0,0,7v5.056A1.946,1.946,0,0,0,1.944,14H12.056A1.946,1.946,0,0,0,14,12.056V7A.5.5,0,0,0,13.854,6.646Z"
        fill="currentColor"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0"
      />
    </g>
  </svg>
);

export default HomeIcon;
