import React from 'react';
import { useSelector } from 'react-redux';
import recipeSelector from '../../../selectors/recipe.selector';

const SVHero = () => {
  const shoppingList = useSelector(recipeSelector.getShoppingListRecipes);
  const renderOstoslistaHero = (recipesLength) => {
    switch (recipesLength) {
      case 1:
        return <h3 className="sv-h3">Listalla 1 resepti</h3>;
      default:
        return (
          <h3 className="sv-h3">
            {`Listalla ${recipesLength} reseptiä`}
          </h3>
        );
    }
  };

  return (
    <div>
      {renderOstoslistaHero(shoppingList.length)}
    </div>
  );
};

export default SVHero;
