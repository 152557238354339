import React from 'react';
import PropTypes from 'prop-types';
import CustomSelect from '../../../common/inputs/CustomSelect';

const RAVOptionalIngredients = ({
  values,
  setValues,
  options,
}) => {
  const handleChange = (selected) => {
    setValues({
      ...values,
      optionalIngredients: selected.map((item) => (
        {
          sk: item.sk,
          name: item.name,
        }
      )),
    });
  };

  return (
    <>
      <h3>Lisäksi</h3>
      <CustomSelect
        id="optionalIngredients"
        placeholder="Valitse..."
        handleChange={handleChange}
        options={options}
        isMulti="true"
      />
    </>
  );
};

RAVOptionalIngredients.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  setValues: PropTypes.instanceOf(Function).isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
};

export default RAVOptionalIngredients;
