import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconChevron } from '../../../../images/icon__chevron.svg';
import SVRecipeBody from './SVRecipeBody';

const SVRecipe = ({ recipe }) => {
  const [visibility, setVisibility] = useState(false);

  const showWhenVisible = { display: visibility ? '' : 'none' };
  // const hideWhenVisible = { display: visibility ? 'none' : '' };

  const toggleVisibility = () => {
    setVisibility(!visibility);
  };

  return (
    <div className="collapsible-recipe__wrapper">
      <div className="collapsible-recipe__header flex flex-jc-sb flex-ai-c" role="button" tabIndex="0" aria-pressed="false" onClick={() => toggleVisibility()} onKeyDown={() => toggleVisibility()}>
        <div className="flex flex-ai-c flex-no-wrap">
          <div className="collapsible-recipe__image-container">
            <img className="collapsible-recipe__image" src={recipe.imageSmall} alt="" />
          </div>
          <p className="collapsible-recipe__title">{recipe.name}</p>
        </div>
        <div id="chev" className={visibility === true ? 'chevron--up' : 'chevron--down'}>
          <IconChevron />
        </div>
      </div>
      <div style={showWhenVisible}>
        <SVRecipeBody recipe={recipe} />
      </div>
    </div>
  );
};

SVRecipe.propTypes = {
  recipe: PropTypes.instanceOf(Object).isRequired,
};

export default SVRecipe;
