import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeFilter, setFilter } from '../../../reducers/category.reducer';
import ButtonSecondary from '../../common/buttons/button-secondary/ButtonSecondary';
import ButtonPrimary from '../../common/buttons/button-primary/ButtonPrimary';
import categorySelector from '../../../selectors/category.selector';

const DVRecipeFilter = () => {
  const categories = useSelector((state) => state.categories);
  const filters = useSelector(categorySelector.getCategoryFilters);
  const recipes = useSelector((state) => state.recipes);
  const recipeCategories = recipes.reduce((result, arr) => result.concat(arr.categories), []);
  const timePKS = ['category#8734427', 'category#8661172'];
  const dispatch = useDispatch();

  const handleClick = (pk) => {
    if (filters.includes(pk)) {
      dispatch(removeFilter(pk));
    } else {
      dispatch(setFilter(pk));
    }
  };

  return (
    <div className="filter-container flex flex-jc-sb flex-jc-c">
      {
        categories.length > 0
          ? (
            categories.map((item) => {
              if (recipeCategories.includes(item.pk) || timePKS.includes(item.pk)) {
                if (filters.includes(item.pk)) {
                  return <ButtonPrimary key={item.pk} text={item.name} handleClick={() => handleClick(item.pk)} size="btn--xs" isNeutral />;
                }
                return <ButtonSecondary key={item.pk} text={item.name} handleClick={() => handleClick(item.pk)} size="btn--xs" isNeutral />;
              }
              return <Fragment key={item.pk} />;
            })
          )
          : <></>
      }
    </div>
  );
};

export default DVRecipeFilter;
