import React from 'react';
import PropTypes from 'prop-types';
import Step from './RAVStep';
import ErrorMessage from '../../common/error-message/ErrorMessage';
import CustomInput from '../../common/inputs/CustomInput';
import ButtonSecondary from '../../common/buttons/button-secondary/ButtonSecondary';
import ButtonTertiary from '../../common/buttons/button-tertiary/ButtonTertiary';

const Instructions = ({ values, setValues, submitted }) => {
  const { instructions } = values;
  const addStep = () => {
    const stepNumber = instructions.length + 1;
    const newInstructions = instructions.concat({ description: '', step: stepNumber });
    setValues({
      ...values,
      instructions: newInstructions,
    });
  };

  const removeStep = () => {
    if (values.instructions.length > 1) {
      const lastStep = instructions.length;
      const newInstructions = instructions.filter((step) => step.step !== lastStep);
      setValues({
        ...values,
        instructions: newInstructions,
      });
    }
  };

  const handleInputChange = (name) => (e) => {
    const { value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <>
      <h2 id="instructions-heading">Ohjeet</h2>
      <CustomInput
        name="duration"
        title="Kokkaukseen kuluva aika"
        min="0"
        value={values.duration}
        handleChange={handleInputChange('duration')}
        required="true"
        type="number"
        submitted={submitted}
      >
        <p className="form-hint">Kokonaisaika minuuteissa.</p>
      </CustomInput>
      <h4>Ohjeet vaiheittain</h4>
      {submitted && instructions.find((step) => step.description === '')
        ? <ErrorMessage message="Täytä puuttuva kuvaus tai poista ylimääräinen vaihe." />
        : <p className="form-hint">Jaa ohjeet sopiviin kokonaisuuksiin ja valitse kussakin vaiheessa tarvittavat ainekset. Tarkistathan oikeinkirjoituksen 🤓</p> }
      {
        instructions.map((step) => (
          <Step
            key={`step${step.step}`}
            num={step.step}
            values={values}
            setValues={setValues}
            submitted={submitted}
          />
        ))
      }
      <div className="flex flex-ai-c">
        <ButtonSecondary handleClick={addStep} text="+ Lisää vaihe" />
        {
          instructions.length <= 1
            ? null
            : <ButtonTertiary handleClick={removeStep} text="Poista vaihe" />
        }
      </div>
    </>
  );
};

Instructions.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  setValues: PropTypes.instanceOf(Function).isRequired,
  submitted: PropTypes.bool.isRequired,
};

export default Instructions;
