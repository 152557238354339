import React from 'react';
import { Link } from 'react-router-dom';
import ButtonPrimary from '../../common/buttons/button-primary/ButtonPrimary';
import HeroImage from '../../../images/hero-image-mini.png';

const HVHero = () => (
  <section className="hv-hero-section">
    <div className="hv-hero-info-container flex flex-vertical flex-ai-c">
      <h1 className="hv-hero__heading is-white text--center">
        Löydä parhaat kasvisreseptit.
      </h1>
      <p className="hv-hero__subheading is-white text--center">Kasvissyönnin ei tarvitse olla hankalaa. Slurps tuo herkullisimmat reseptit samaan paikkaan ja tekee vegeruoan kotona kokkaamisesta helpompaa kuin koskaan.</p>
      <Link className="page-link" to="/reseptit">
        <ButtonPrimary text="Katso kaikki reseptit" size="btn--lg" />
      </Link>
    </div>
    <div className="hv-hero-image-container">
      <img src={HeroImage} alt="Collection of delicious food" />
    </div>
  </section>
);

export default HVHero;
