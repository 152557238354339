import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RecipeInfo from './RVRecipeInfo';
import RVIngredients from './ingredients/RVIngredients';
import RVInstructions from './instructions/RVInstructions';
import RVMealCounter from './ingredients/meal-counter/RVMealCounter';

const RecipeViewContent = ({
  recipe,
}) => {
  const [view, setView] = useState('ainekset');

  const toggleView = (viewName) => {
    setView(viewName);
  };

  return (
    <section className="rv-content">
      <RecipeInfo
        recipe={recipe}
      />
      <div className="rv-content-wrapper">
        <div className="toggle-view">
          <button className={view === 'ainekset' ? 'rv-toggle--active' : 'rv-toggle'} type="button" onClick={() => toggleView('ainekset')}>Ainekset</button>
          <button className={view === 'ohjeet' ? 'rv-toggle--active' : 'rv-toggle'} type="button" onClick={() => toggleView('ohjeet')}>Ohjeet</button>
        </div>
        <div className="rv-content-container">
          <RVMealCounter pk={recipe.pk} meals={recipe.meals} />
          {
            view === 'ainekset'
              ? (
                <RVIngredients
                  recipe={recipe}
                  ingredients={recipe.ingredients}
                  optionalIngredients={recipe.optionalIngredients}
                />
              )
              : (
                <RVInstructions
                  recipe={recipe}
                  ingredients={recipe.ingredients}
                />
              )
          }
        </div>
      </div>
    </section>
  );
};

RecipeViewContent.propTypes = {
  recipe: PropTypes.instanceOf(Object).isRequired,
};

export default RecipeViewContent;
