import React from 'react';

const CartIcon = () => (
  <svg
    viewBox="0 0 20 20"
    height="20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(1.4285714285714286,0,0,1.4285714285714286,0,0)">
      <path
        d="M13.25,0H11.664a1.25,1.25,0,0,0-1.228,1.016L10.192,2.3a.249.249,0,0,1-.245.2H1.25A1.25,1.25,0,0,0,.024,4l.8,4A1.253,1.253,0,0,0,2.05,9H8.613a.251.251,0,0,1,.245.3l-.19,1a.251.251,0,0,1-.246.2H2.25a.75.75,0,0,0,0,1.5h.5a1.251,1.251,0,1,0,2,0h2a1.251,1.251,0,1,0,2,0h.087a1.249,1.249,0,0,0,1.227-1.016L11.832,1.7a.251.251,0,0,1,.246-.2H13.25a.75.75,0,0,0,0-1.5ZM3.5,6.75a.5.5,0,0,1-1,0v-2a.5.5,0,0,1,1,0Zm2.5,0a.5.5,0,0,1-1,0v-2a.5.5,0,0,1,1,0Zm2.5,0a.5.5,0,0,1-1,0v-2a.5.5,0,0,1,1,0Z"
        fill="currentColor"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0"
      />
    </g>
  </svg>
);

export default CartIcon;
