import axios from 'axios';
import qs from 'qs';

const getAll = async () => {
  const url = 'https://1q3tq8h3q4.execute-api.eu-north-1.amazonaws.com/default/getRecipes';
  const response = await axios.get(url);
  return response.data;
};

const getRecipeIngredients = async (pk) => {
  const url = 'https://a7jd62g0q2.execute-api.eu-north-1.amazonaws.com/default/getRecipeIngredients';
  const params = {
    pk,
  };
  const response = await axios.get(url, { params });
  return response.data;
};

const getIngredients = async () => {
  const url = 'https://0kqoh7zvlg.execute-api.eu-north-1.amazonaws.com/default/getIngredients';
  const response = await axios.get(url);
  return response.data;
};

const getCategories = async () => {
  const url = 'https://j4ha0pexij.execute-api.eu-north-1.amazonaws.com/default/getCategories';
  const response = await axios.get(url);
  return response.data;
};

const getIngredientBatch = async (pks) => {
  const url = 'https://wlkz5omrx1.execute-api.eu-north-1.amazonaws.com/default/getIngredientBatch';
  const params = {
    pks,
  };
  const response = await axios.get(
    url,
    {
      params,
      paramsSerializer: (item) => qs.stringify(item),
    },
  );
  return response.data;
};

const addImage = async (name, image) => {
  const data = new FormData();
  data.append(name, image);
  const response = await axios.post('https://dhn1p4djd3.execute-api.eu-north-1.amazonaws.com/default/addRecipeImage', data);
  return response.data;
};

const addRecipe = async (values) => {
  console.log(values);
  const response = await axios.post('https://79484j5moa.execute-api.eu-north-1.amazonaws.com/default/addRecipe', JSON.stringify(values));
  return response.data;
};

const createPK = async () => {
  const response = await axios.get('https://9xrsqo1nhf.execute-api.eu-north-1.amazonaws.com/default/createPK');
  return response.data;
};

const create = async (recipe) => {
  const data = new FormData();
  Object.entries(recipe).forEach(([key, value]) => {
    if (key === 'image') {
      data.append(key, value);
    } else {
      data.append(key, JSON.stringify(value));
    }
  });
  const response = await axios.post('https://7y0sny2ul5.execute-api.eu-north-1.amazonaws.com/default/createRecipe', data);
  return response;
};

export default {
  addRecipe,
  getAll,
  create,
  createPK,
  addImage,
  getRecipeIngredients,
  getIngredients,
  getCategories,
  getIngredientBatch,
};
