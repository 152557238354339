import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import RVHero from './hero/RVHero';
import RVContent from './content/RVContent';
import Loading from '../common/loading/Loading';

const RecipeView = ({
  recipe,
}) => {
  const loading = useSelector((state) => state.loading);
  return (
    <>
      {
        loading
          ? <Loading />
          : (
            <>
              <RVHero recipe={recipe} />
              <RVContent
                recipe={recipe}
              />
              <div className="rv-bg-hack-container">
                <div className="rv-bg-hack" />
              </div>
            </>
          )
      }
    </>
  );
};

RecipeView.propTypes = {
  recipe: PropTypes.instanceOf(Object).isRequired,
};

export default RecipeView;
