import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import IconWithText from '../../common/icon-with-text/IconWithText';
import iconCook from '../../../images/icon__chef.svg';
import iconDuration from '../../../images/icon__duration.svg';
import iconMeals from '../../../images/icon__meals.svg';
import { addToShoppingList, removeFromShoppingList } from '../../../reducers/recipe.reducer';
import ButtonPrimary from '../../common/buttons/button-primary/ButtonPrimary';
import ButtonSecondary from '../../common/buttons/button-secondary/ButtonSecondary';

const RVRecipeInfo = ({
  recipe,
}) => {
  const dispatch = useDispatch();

  const handleAdd = () => {
    dispatch(addToShoppingList(recipe.pk));
  };

  const handleRemove = () => {
    dispatch(removeFromShoppingList(recipe.pk));
  };

  return (
    <div className="rv-info flex flex-jc-sb">
      <div className="flex">
        <IconWithText iconUrl={iconDuration} text={`${recipe.duration} min`} />
        {
          recipe.chef
            ? <IconWithText iconUrl={iconCook} text={` Tekijä: ${recipe.chef}`} link={recipe.website} />
            : <></>
        }
      </div>
      <div className="fixed-bottom-container">
        <div className="fixed-bottom-container__content flex flex-ai-c">
          <div className="hide-for-mobile hide-for-tablet-up hide-for-desktop flex flex-jc-sb flex-ai-c">
            <img src={iconMeals} alt="Icon indicating meals" />
            &ensp;
            <span className="fixed-bottom-container__text">{recipe.meals}</span>
            &nbsp;
            <span className="fixed-bottom-container__text">annosta</span>
          </div>
          {
          recipe.added
            ? <ButtonSecondary handleClick={handleRemove} text="Poista ostoslistalta" />
            : <ButtonPrimary handleClick={handleAdd} text="+ Lisää ostoslistalle" />
          }
        </div>
      </div>
    </div>
  );
};

RVRecipeInfo.propTypes = {
  recipe: PropTypes.instanceOf(Object).isRequired,
};

export default RVRecipeInfo;
