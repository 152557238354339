/* eslint-disable max-len */
import React from 'react';

const HVFeedback = () => (
  <section className="feedback-section flex flex-vertical">
    <div className="feedback-container flex flex-jc-c">
      <div className="feedback-text-container">
        <h3 className="is-white">Mitä olet mieltä?</h3>
        <p className="is-white">Mikä tekisi sinulle parhaan reseptisivuston? Haluatko vinkata reseptin? Muuta palautetta? Kerro meille! 🤩</p>
      </div>
      <div className="feedback-tally">
        <iframe className="feedback-tally__iframe" src="https://tally.so/embed/wbZ0Zw?hideTitle=1&alignLeft=1&transparentBackground=1" width="100%" height="100%" frameBorder="0" marginHeight="0" marginWidth="0" title="Anna palautetta" />
      </div>
    </div>

  </section>
);

export default HVFeedback;
