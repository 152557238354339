import _ from 'lodash';

const recipeReducer = (state = [], action) => {
  switch (action.type) {
    case 'ADD_RECIPE': {
      const newState = [...state, action.data];
      return newState;
    }
    case 'REMOVE_RECIPE': {
      const newState = state.filter((item) => item.pk !== action.pk);
      return newState;
    }
    case 'ADD_MEALS': {
      const newState = state.map((item) => {
        if (item.pk === action.pk) {
          const newItem = item;
          newItem.meals += 1;
          return newItem;
        }
        return item;
      });
      return newState;
    }
    case 'REDUCE_MEALS': {
      const newState = state.map((item) => {
        if (item.pk === action.pk) {
          const newItem = item;
          newItem.meals -= 1;
          return newItem;
        }
        return item;
      });
      return newState;
    }
    case 'SET_MEALS': {
      const newState = state.map((item) => {
        if (item.pk === action.pk) {
          const newItem = item;
          newItem.meals = action.meals;
          return newItem;
        }
        return item;
      });
      return newState;
    }
    case 'SET_INGREDIENTS': {
      const newState = state.map((item) => {
        if (item.pk === action.pk) {
          const newItem = {
            ...item,
            ingredients: action.ingredients,
          };
          return newItem;
        }
        return item;
      });
      return newState;
    }
    case 'SET_OPTIONAL_INGREDIENTS': {
      const newState = state.map((item) => {
        if (item.pk === action.pk) {
          const newItem = {
            ...item,
            optionalIngredients: action.optionalIngredients,
          };
          return newItem;
        }
        return item;
      });
      return newState;
    }
    case 'ADD_TO_SHOPPING_LIST': {
      const newState = state.map((item) => {
        if (item.pk === action.pk) {
          const newItem = item;
          newItem.added = true;
          return newItem;
        }
        return item;
      });
      return newState;
    }
    case 'REMOVE_FROM_SHOPPING_LIST': {
      const newState = state.map((item) => {
        if (item.pk === action.pk) {
          const newItem = item;
          newItem.added = false;
          return newItem;
        }
        return item;
      });
      return newState;
    }
    case 'EMPTY_SHOPPING_LIST': {
      const newState = state.map((item) => {
        const newItem = item;
        newItem.added = false;
        return newItem;
      });
      return newState;
    }
    case 'EMPTY_RECIPES': {
      return [];
    }
    default:
      return state;
  }
};

export const addRecipe = (recipe) => (
  {
    type: 'ADD_RECIPE',
    data: recipe,
  }
);

export const removeRecipe = (pk) => (
  {
    type: 'REMOVE_RECIPE',
    pk,
  }
);

export const addMeals = (pk) => (
  {
    type: 'ADD_MEALS',
    pk,
  }
);

export const reduceMeals = (pk) => (
  {
    type: 'REDUCE_MEALS',
    pk,
  }
);

export const setMeals = (pk, meals) => (
  {
    type: 'SET_MEALS',
    pk,
    meals,
  }
);

export const setIngredients = (pk, ingredients) => (
  {
    type: 'SET_INGREDIENTS',
    pk,
    ingredients,
  }
);

export const setOptionalIngredients = (pk, optionalIngredients) => (
  {
    type: 'SET_OPTIONAL_INGREDIENTS',
    pk,
    optionalIngredients,
  }
);

export const addToShoppingList = (pk) => (
  {
    type: 'ADD_TO_SHOPPING_LIST',
    pk,
  }
);

export const removeFromShoppingList = (pk) => (
  {
    type: 'REMOVE_FROM_SHOPPING_LIST',
    pk,
  }
);

export const emptyShoppingList = () => (
  {
    type: 'EMPTY_SHOPPING_LIST',
  }
);

export const emptyRecipes = () => (
  {
    type: 'EMPTY_RECIPES',
  }
);
export const createSummedShoppingList = (ingredients, recipes) => {
  const result = ingredients.reduce((res, item) => {
    const key = `${item.sk}-${item.unit}`;
    if (!res[key]) {
      res[key] = {
        name: item.plural,
        category: item.category,
        amount: 0,
        unit: item.unit,
      };
    }
    const { meals } = recipes.find((recipe) => recipe.pk === item.pk);
    res[key].amount += (parseFloat(item.amount) * meals);
    return res;
  }, {});
  return _.groupBy(Object.values(result), 'category');
};

export default recipeReducer;
